import React from 'react'

interface HeaderTextProps {

}

const HeaderText: React.FC<HeaderTextProps> = ({ }) => {
  return (
    <div className='text-[40px] font-bold font-sans leading-10'>
      Enrol to our IWS Double Diploma for 2024
    </div>
  )
}

export default HeaderText