export const Programms = [
  {
    "name": "Primary School",
    "age": "Ages between 8-10",
    "discount": 0,
    "min_select": 1,
    "selected": 5,
    "condition": {
      "selected": 5,
      "discount": 9.0909
    },
    "package": [
      {
        'id': 1,
        'name': 'Maths',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 2,
        'name': 'English',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 3,
        'name': 'Science',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 4,
        'name': 'Computing',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Global Perspectives',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 6,
        'name': 'French',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 7,
        'name': 'Spanish',
        'price': 1026.66,
        'exist': false
      },
      {
        'id': 8,
        'name': 'German',
        'price': 1026.66,
        'exist': false
      },
    ],
  },
  {
    "name": "Secondary School",
    "age": "Ages between 11-13",
    "discount": 0,
    "min_select": 1,
    "selected": 5,
    "condition": {
      "selected": 5,
      "discount": 9.0909
    },
    "package": [
      {
        'id': 1,
        'name': 'Maths',
        'price': 1232,
        'exist': true
      },
      {
        'id': 2,
        'name': 'English',
        'price': 1232,
        'exist': true
      },
      {
        'id': 3,
        'name': 'Science',
        'price': 1232,
        'exist': true
      },
      {
        'id': 4,
        'name': 'Computing',
        'price': 1232,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Global Perspectives',
        'price': 1232,
        'exist': false
      },
      {
        'id': 6,
        'name': 'French',
        'price': 1232,
        'exist': false
      },
      {
        'id': 7,
        'name': 'Spanish',
        'price': 1232,
        'exist': false
      },
      {
        'id': 8,
        'name': 'German',
        'price': 1232,
        'exist': false
      },
    ],
  },
  {
    "name": "I/GCSE School",
    "age": "Ages between 14-16",
    "discount": 0,
    "min_select": 1,
    "selected": 5,
    "condition": {
      "selected": 5,
      "discount": 9.0909
    },
    "package": [
      {
        'id': 1,
        'name': 'Biology',
        'price': 1500,
        'exist': true
      },
      {
        'id': 2,
        'name': 'Business Studies',
        'price': 1500,
        'exist': true
      },
      {
        'id': 3,
        'name': 'Chemistry',
        'price': 1500,
        'exist': true
      },
      {
        'id': 4,
        'name': 'Computer Science',
        'price': 1500,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Economics',
        'price': 1500,
        'exist': false
      },
      {
        'id': 6,
        'name': 'English - First Language',
        'price': 1500,
        'exist': false
      },
      {
        'id': 7,
        'name': 'English - Literature in English',
        'price': 1500,
        'exist': false
      },
      {
        'id': 8,
        'name': 'English (as an Additional Language)',
        'price': 1500,
        'exist': false
      },
      {
        'id': 9,
        'name': 'Geography',
        'price': 1500,
        'exist': false
      },
      {
        'id': 10,
        'name': 'Global Perspectives',
        'price': 1500,
        'exist': false
      },
      {
        'id': 11,
        'name': 'History',
        'price': 1500,
        'exist': false
      },
      {
        'id': 12,
        'name': 'Mathematics',
        'price': 1500,
        'exist': false
      },
      {
        'id': 13,
        'name': 'Mathematics - Additional',
        'price': 1500,
        'exist': false
      },
      {
        'id': 14,
        'name': 'Physics',
        'price': 1500,
        'exist': false
      },
      {
        'id': 15,
        'name': 'Sociology',
        'price': 1500,
        'exist': false
      },
      {
        'id': 16,
        'name': 'Spanish',
        'price': 1500,
        'exist': false
      },
    ],
  },

]

export const ALevelProgramm = {
  "name": "A Level",
  "age": "Ages between 16-18",
  "price_condition": [
    { 1: 1500 },
    { 2: 3000 },
    { 3: 4500 },
    { 4: 6000 },
    { 5: 7500 },
    { 6: 8288.28 },
    { 7: 9669.66 },
    { 8: 11051.04 },
    { 9: 12432.42 },
    { 10: 13813.8 },
    { 11: 15195.18 },
    { 12: 16576.56 },
    { 13: 17957.94 },
    { 14: 19339.32 },
    { 15: 20720.7 },
    { 16: 22102.08 },
    { 17: 23483.46 },
  ],
  "discount": 0,
  "min_select": 1,
  "selected": 3,
  "package": [
    {
      'id': 1,
      'name': 'Biology',
      'price': 2750,
      'exist': true
    },
    {
      'id': 2,
      'name': 'Business',
      'price': 2750,
      'exist': true
    },
    {
      'id': 3,
      'name': 'Chemistry',
      'price': 2750,
      'exist': true
    },
    {
      'id': 4,
      'name': 'Computer Science',
      'price': 2750,
      'exist': false
    },
    {
      'id': 5,
      'name': 'Economics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 6,
      'name': 'English - Literature',
      'price': 2750,
      'exist': false
    },
    {
      'id': 7,
      'name': 'English Language',
      'price': 2750,
      'exist': false
    },
    {
      'id': 8,
      'name': 'Geography',
      'price': 2750,
      'exist': false
    },
    {
      'id': 9,
      'name': 'Global Perspectives & Research',
      'price': 2750,
      'exist': false
    },
    {
      'id': 10,
      'name': 'History',
      'price': 2750,
      'exist': false
    },
    {
      'id': 11,
      'name': 'Law',
      'price': 2750,
      'exist': false
    },
    {
      'id': 12,
      'name': 'Mathematics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 13,
      'name': 'Mathematics - Further',
      'price': 2750,
      'exist': false
    },
    {
      'id': 14,
      'name': 'Media Studies',
      'price': 2750,
      'exist': false
    },
    {
      'id': 15,
      'name': 'Physics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 16,
      'name': 'Psychology',
      'price': 2750,
      'exist': false
    },
    {
      'id': 17,
      'name': 'Sociology',
      'price': 2750,
      'exist': false
    },
  ],
}