import React from 'react'
import MainText from '../../text/MainText'
import { Subject, Package, ALevelProgram, date } from '../../../utils/Interfaces'
import NormalLightText from '../../text/NormalLightText';
import NormalText from '../../text/NormalText';
import BlueBoldText from '../../text/BlueBoldText';
import BlueNormalText from '../../text/BlueNormalText';

interface PaymentSectionProps {
  desiredDate: null | date;
  selectedPackage: Package | ALevelProgram | null;
  selectedSubjects: Subject[] | null;
  priceWithCondition: number | null;
  setPriceWithCondition: React.Dispatch<React.SetStateAction<number | null>>;
  priceWithDiscount: number | null;
  setPriceWithDiscount: React.Dispatch<React.SetStateAction<number | null>>;
}
const PaymentSection: React.FC<PaymentSectionProps> = ({ selectedPackage, selectedSubjects, priceWithCondition, setPriceWithCondition, priceWithDiscount, setPriceWithDiscount, desiredDate }) => {

  function calculateAnnualPaymentWithCondition(selectedSubjects: Subject[] | null, programOrPackage: Package | ALevelProgram) {
    let sum = 0;
    if ('condition' in programOrPackage && programOrPackage.condition) {
      if (selectedSubjects) {
        selectedSubjects.forEach(sub => {
          sum += sub.price;
        });

        // Check if programOrPackage is a Package by looking for the 'condition' property
        // Ensure selectedSubjects length meets or exceeds the condition for a discount
        if (selectedSubjects.length >= programOrPackage.condition.selected && selectedSubjects.length < 6) {
          sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
        }
        if (selectedSubjects.length >= 6) {
          sum = 0
          if (selectedPackage?.name === "Primary School") {
            selectedSubjects.slice(0, 5).forEach((sub, index) => {
              sum += sub.price;
              // You can perform other operations here as needed
            });
            sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
            selectedSubjects.slice(5).forEach((sub, index) => {
              sum += 840;
              // You can perform other operations here as needed
            });
            // sum *= (1 - 18.1818 / 100); // Apply discount
          }


          if (selectedPackage?.name === "Secondary School") {
            selectedSubjects.slice(0, 5).forEach((sub, index) => {
              sum += sub.price;
              // You can perform other operations here as needed
            });
            sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
            selectedSubjects.slice(5).forEach((sub, index) => {
              sum += 1008;
            });
          }

          if (selectedPackage?.name === "I/GCSE School") {
            selectedSubjects.slice(0, 5).forEach((sub, index) => {
              sum += sub.price;
              // You can perform other operations here as needed
            });
            sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
            selectedSubjects.slice(5).forEach((sub, index) => {
              sum += 604.8;
            });
          }
        }
      }
    }
    if ('price_condition' in programOrPackage && programOrPackage.price_condition) {
      if (selectedSubjects) {
        const numberOfSubjects = selectedSubjects.length;
        const priceConditionItem = programOrPackage.price_condition.find(item => item[numberOfSubjects]);

        if (priceConditionItem) {
          sum = priceConditionItem[numberOfSubjects] ?? 0;
        }
      }
    }
    return sum
  }

  function calculateAnnualPaymentWithDiscount() {
    if (selectedPackage && selectedPackage.discount !== 0 && selectedSubjects) {
      const basePayment = calculateAnnualPaymentWithCondition(selectedSubjects, selectedPackage);
      // const basePayment = calculateAnnualPaymentWithCondition(selectedSubjects);
      const discountAmount = selectedPackage.discount / 100 * basePayment;
      return (basePayment - discountAmount).toFixed(2);
    } else {
      if (selectedPackage) {
        return (calculateAnnualPaymentWithCondition(selectedSubjects, selectedPackage)).toFixed(2);
      }
    }
  }

  return (
    <div>
      <MainText text={"Your Payment"} />
      <div className='w-full mt-2 h-[2px] rounded-full bg-black' />

      <div className='py-4 flex justify-between'>
        <NormalLightText text={"Annual Payment"} />
        <div className='text-right'>
          {
            selectedPackage && (
              <NormalText text={`£${calculateAnnualPaymentWithCondition(selectedSubjects, selectedPackage).toFixed(2)}`} />
            )
          }
        </div>
      </div>
      <div className='w-full mt-2 h-[2px] rounded-full bg-black' />
      <div className='py-4 flex justify-between'>
        <MainText text={"Registration Fee"} />
        <div className='text-right'>
          {
            selectedPackage && (
              <MainText text={`£200`} />
            )
          }
        </div>
      </div>






    </div>
  )
}

export default PaymentSection